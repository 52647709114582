/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

 .d-block {
  display: block !important;
}

.w-100 {
  width: 100% !important;
}

.carousel {
  position: relative;
  background-color: #000;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  /* display: inline-block;
  width: 30px;
  height: 40px;
  background: no-repeat 50% / 100% 100%; */
  width: 30px;
	background: url(../img/light-skin-1.png);
	height: 40px;
	cursor: pointer;
	top: 50%;
	margin-top: -20px;
	position: absolute;
	z-index: 110;
}


.carousel-control-prev-icon {
  /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); */
  background-position: -89px -26px;
}

.carousel-control-next-icon {
  /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); */
  background-position: -89px -103px;

}

.carousel .carousel-control-prev, .carousel .carousel-control-next {
  visibility: hidden;
  opacity:0;
  transition:visibility 0.4s linear,opacity 0.4s linear;
}

.carousel:hover .carousel-control-prev, .carousel:hover .carousel-control-next {
  visibility: visible;
  opacity:1;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  /* top: 34%; */
  /* transform: translateY(-50%); */
}

img {
  object-fit: cover;
}

@media (max-width: 1400px){
  .carousel-item img{
    height: 500px;
  }

  .carousel-item img.height  {
    height: 600px;
  }
}

.height  {
  height: 65.16vh;
}

.text1 {
  color: #fff;
  font-size: 50px;
  line-height: 50px;
  text-align: left;
  padding: 0;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  margin-bottom: 25px;
}

.text2 {
	color: #fff;
	font-size: 70px;
	line-height: 23px;
	text-align: left;
	text-transform: uppercase;
	font-family: 'Roboto', sans-serif;
  font-weight: bold;
  margin-bottom: 30px;
}

.text3 {
	color: #fff;
	font-size: 16px;
	line-height: 26px;
	text-align: left;
	font-family: 'Raleway', sans-serif;
  font-weight: normal;
  margin-bottom: 25px;
}

.button {
  background-color: #F6B800;
  color: #fff;
	font-size: 14px;
	line-height: 16px;
	font-family: "Raleway", sans-serif;
	font-weight: 500;
	padding: 13px 36px;
	cursor: pointer;
	-moz-border-radius: 20px;
	-webkit-border-radius: 20px;
	border-radius: 2px;
  transition: all 0.3s ease 0s;
  float: left;
}

.button:hover {
	color: #242424;
	background-color: #fff;
}

.slide-left {
  position: relative;
  left: -500px;
  opacity: 0;
  -webkit-animation: slide-left .8s forwards; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: slide-left .8s forwards; /* Firefox < 16 */
   -ms-animation: slide-left .8s forwards; /* Internet Explorer */
    -o-animation: slide-left .8s forwards; /* Opera < 12.1 */
       animation: slide-left .8s forwards;
  -webkit-animation-delay: .7s;
  animation-delay: .7s;
}


@keyframes slide-left {
    100% { left: 0; opacity: 1; }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes slide-left {
    100% { left: 0; opacity: 1; }
}
/* Firefox < 16 */
@-moz-keyframes slide-left {
  100% { left: 0; opacity: 1; }
}
/* Internet Explorer */
@-ms-keyframes slide-left {
  100% { left: 0; opacity: 1; }
}
/* Opera < 12.1 */
@-o-keyframes slide-left {
  100% { left: 0; opacity: 1; }
}

.slide-right {
  position: relative;
  right: -300px;
  opacity: 0;
  -webkit-animation: slide-right .8s forwards; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: slide-right .8s forwards; /* Firefox < 16 */
   -ms-animation: slide-right .8s forwards; /* Internet Explorer */
    -o-animation: slide-right .8s forwards; /* Opera < 12.1 */
       animation: slide-right .8s forwards;
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

@keyframes slide-right {
    100% { right: 0; opacity: 1; }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes slide-right {
    100% { right: 0; opacity: 1; }
}
/* Firefox < 16 */
@-moz-keyframes slide-right {
  100% { right: 0; opacity: 1; }
}
/* Internet Explorer */
@-ms-keyframes slide-right {
  100% { right: 0; opacity: 1; }
}
/* Opera < 12.1 */
@-o-keyframes slide-right {
  100% { right: 0; opacity: 1; }
}

.slide-top {
  position: relative;
  top: -50px;
  opacity: 0;
  -webkit-animation: slide-top .8s forwards; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: slide-top .8s forwards; /* Firefox < 16 */
   -ms-animation: slide-top .8s forwards; /* Internet Explorer */
    -o-animation: slide-top .8s forwards; /* Opera < 12.1 */
       animation: slide-top .8s forwards;
  -webkit-animation-delay: 2.1s;
  animation-delay: 2.1s;
}

@keyframes slide-top {
    100% { top: 0; opacity: 1; }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes slide-top {
  100% { top: 0; opacity: 1; }
}
/* Firefox < 16 */
@-moz-keyframes slide-top {
  100% { top: 0; opacity: 1; }
}
/* Internet Explorer */
@-ms-keyframes slide-top {
  100% { top: 0; opacity: 1; }
}
/* Opera < 12.1 */
@-o-keyframes slide-top {
  100% { top: 0; opacity: 1; }
}

.button.slide-left {
  -webkit-animation: slide-left .6s forwards; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: slide-left .6s forwards; /* Firefox < 16 */
   -ms-animation: slide-left .6s forwards; /* Internet Explorer */
    -o-animation: slide-left .6s forwards; /* Opera < 12.1 */
       animation: slide-left .6s forwards;
  -webkit-animation-delay: 2.8s;
  animation-delay: 2.8s;
}

.carousel {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
   -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
       animation: fadein 2s;
}


@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
